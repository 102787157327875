import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { Container, Dropdown, Text, Icon, Button } from "@atoms";
import { Card, SearchInput } from "@molecules";
import { m, AnimatePresence } from "framer-motion";
import MiniSearch from "minisearch";
import convertToOptions from "@utils/convertToOptions";

const ResourcesGrid = ({ resources }) => {
  const { resourceCollections: collections, audiences } = useCategoriesData();
  const [tab, setTab] = useState(
    resources.internal.length ? "internal" : "external"
  );

  const [loaded, setLoaded] = useState(false);
  const [topicFilter, setTopicFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [audienceFilter, setAudienceFilter] = useState("all");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(6);
  const urlValue =
    typeof window !== "undefined" ? window.location?.hash?.split("#")[1] : null;

  const miniSearch = useMemo(
    () =>
      new MiniSearch({
        fields: ["title", "description", "type"],
        storeFields: ["uid"],
        searchOptions: {
          boost: { title: 3 },
          prefix: true,
          fuzzy: 0.2,
        },
      }),
    []
  );

  useEffect(() => {
    miniSearch.addAll(
      [...resources.internal, ...resources.external].map(r => ({
        ...r,
        id: r.uid,
      }))
    );
  }, []);

  const onSearchInput = e => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (query && query.length) {
      const res = miniSearch.search(query);
      setResults(res.map(r => r.uid));
    }
  }, [query]);

  // set topic based on the url parameter
  useEffect(() => {
    if (urlValue) {
      setTab(urlValue);
    }
  }, [urlValue]);

  useEffect(() => {
    const { search: paramsString } = window.location;
    const searchParams = new URLSearchParams(paramsString);
    const topic = searchParams.get("topic");
    const type = searchParams.get("type");
    const audience = searchParams.get("audience");
    const currentTab = searchParams.get("tab");
    const q = searchParams.get("q");
    if (topic) setTopicFilter(topic);
    if (type) setTypeFilter(type);
    if (audience) setAudienceFilter(audience);
    if (q) setQuery(q);
    if (currentTab) setTab(currentTab);
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      const { search: paramsString } = window.location;
      const searchParams = new URLSearchParams(paramsString);

      searchParams.set("topic", topicFilter);
      searchParams.set("type", typeFilter);
      searchParams.set("audience", audienceFilter);
      searchParams.set("tab", tab);
      searchParams.set("q", query);

      const url = `${
        window.location.href.split("?")[0]
      }?${searchParams.toString()}`;

      // convert the object to a query string
      // and overwrite the existing query string
      // window.location.search = searchParams.toString();
      // newLocation.search = searchParams.toString();
      // console.log(newLocation.href);
      // window.history.replaceState({}, document.title, newLocation.href);
      window.history.replaceState({}, document.title, url);
    }
  }, [topicFilter, typeFilter, audienceFilter, query, tab, loaded]);

  const tabs = [];

  if (!resources.internal.length) {
    tabs.push({ external: "Resources" });
  } else if (!resources.external.length) {
    tabs.push({ internal: "Resources" });
  } else {
    tabs.push({ internal: "Our Resources" });
    tabs.push({ external: "External Resources" });
  }

  if (resources.collections.length) {
    tabs.push({ collections: "Collections" });
  }

  // const types = [
  //   { all: "All Mediums" },
  //   { academicArticle: "Academic Articles" },
  //   { articleEssay: "Articles & Essays" },
  //   { book: "Books" },
  //   { video: "Videos" },
  // ];
  const [showFilters, setShowFilters] = useState(false);

  const filteredResources =
    resources[tab]?.length &&
    resources[tab].filter(
      r =>
        tab === "collections" ||
        ((!query.length || results.includes(r.uid)) &&
          (typeFilter === "all" || r.type === typeFilter) &&
          (topicFilter === "all" ||
            r.topics?.map(top => top.slug).includes(topicFilter)) &&
          (audienceFilter === "all" ||
            r.audiences?.map(aud => aud.slug).includes(audienceFilter)))
    );

  return (
    <section className="mx-auto mt-8 max-w-7xl px-4 md:mt-16">
      {/* search + filter */}
      <Container variant="sm">
        {/* mobile button filter */}
        <button
          type="button"
          className="flex w-full items-center justify-center gap-1 rounded-xl bg-purple py-1 text-base font-bold text-white sm:-mb-4 md:hidden"
          onClick={() => setShowFilters(s => !s)}
        >
          <Icon name="filters" className="h-6 w-6" />
          {showFilters ? "Hide" : "Show"} Filters &amp; Search
        </button>

        {/* actual filter + search */}
        <div
          className={clsx(
            "relative w-full items-center justify-center gap-3 transition-all md:mr-0 md:h-auto md:gap-6 md:overflow-visible md:pt-0 md:opacity-100 lg:flex",
            {
              "h-[210px] pt-6": showFilters,
              "h-0 overflow-hidden opacity-0": !showFilters,
            }
          )}
        >
          <div className="hidden h-8 flex-none shrink-0 items-center font-bold md:flex">
            Filter By:
          </div>
          <div className="grid grow grid-cols-4 items-center gap-3 md:gap-6">
            <Dropdown
              model={[topicFilter, setTopicFilter]}
              className="col-span-full w-full md:col-span-1"
              options={[
                { all: "All Content Types" },
                ...collections.map(convertToOptions),
              ]}
            />
            <Dropdown
              model={[audienceFilter, setAudienceFilter]}
              className="col-span-full w-full md:col-span-1"
              options={[
                { all: "All Audience Types" },
                ...audiences.map(convertToOptions),
              ]}
            />
            <SearchInput
              onChange={onSearchInput}
              className="col-span-full mt-3 w-full md:col-span-2 md:mt-0 md:pl-6"
            />
          </div>
        </div>
      </Container>

      {/* resources */}

      <Container variant="sm" className="mt-8 md:mt-16">
        <AnimatePresence>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
            {!!resources[tab]?.length &&
              filteredResources?.slice(0, limit).map((r, i) => (
                <m.li
                  key={r.uid}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: i * 0.05 },
                  }}
                  className="block h-full w-full"
                >
                  <Card
                    className="h-full w-full"
                    {...r}
                    link={`${r.url}#s`}
                    readMore
                  />
                </m.li>
              ))}
            {(!resources[tab]?.length || filteredResources?.length === 0) && (
              <div className="col-span-3 flex min-h-1/4-screen flex-col items-center justify-center space-y-3 text-white">
                <Text variant="h5">Your query returned no results.</Text>
                <Text variant="sm">
                  Try adjusting your search terms and filters.
                </Text>
              </div>
            )}
          </ul>
          {filteredResources?.length > limit && (
            <div className="col-span-3 mt-8 text-center duration-200 md:mt-12">
              <Button
                color="purple"
                size="md"
                onClick={() => setLimit(filteredResources.length)}
              >
                View All
              </Button>
            </div>
          )}
        </AnimatePresence>
      </Container>
    </section>
  );
};

export default ResourcesGrid;
